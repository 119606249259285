<script setup>
import {
	Toast
} from 'vant';
import {
	ref,
	inject
} from 'vue';
import {
	computationalAccuracy
} from '@/plugin/public.js';

const ctx = inject('ctx');
let {
	route
} = inject('ctx');
const $require = inject('$require');
let pageData = ref(null);
document.title = ctx.router.currentRoute.value.query.title;
let maskShow = ref(false);
let payPrice = ref(0);
let disabled = ref(false);
/***
 *
 * 页面数据
 */
let getDetail = () => {
	$require.HttpPost('/h5/payment/getPayMessage', {
		contract_fee_name: ctx.router.currentRoute.value.query.title,
	})
		.then((res) => {
			if (res.data.activity_image) {
          try {
            res.data.activity_image = JSON.parse(res.data.activity_image);
          } catch {
            res.data.activity_image = [res.data.activity_image];
          }
        }
			pageData.value = res.data;
			payPrice.value = res.data.key_pay_price_discount;
			calcMax();
		});
};
getDetail();
/**
 * 支付
 */
let submit = () => {
	$require.HttpPost('/h5/payment/weChatNotice', {
		contract_fee_name: ctx.router.currentRoute.value.query.title,
		key_update_price: payPrice.value,
		balance_deduction: 0,
		residue_deduction: 0,
	})
		.then((res) => {

		});
};
/**
 * 输入框失去焦点
 */
let inputChange = () => {
	if (payPrice.value < 0) {
		payPrice.value = 0;
		return Toast('请输入正确金额');
	}
	calcMax();
};
// 计算可缴纳最大金额
let calcMax = () => {
	let keyPrice = pageData.value.key_pay_price; //应缴金额
	if (payPrice.value > keyPrice) {
		Toast(`最大可缴金额为${keyPrice}`);
		payPrice.value = keyPrice;
	}
};
// 计算减免后金额
let residue_price = ref(0); //减免
let balance = ref(0);
let discount_money = ref(0);
let calcSum = () => {
	let sum = 0;
	if (pageData.value.owe_type == 1) {
		let ye = Number(pageData.value.balance) || 0;
		sum = 0;

		//  余额大于缴费金额减去抵扣金额等于本身
		balance.value = ye;
		//  活动
		if (pageData.value.owe_type == 1) {
			if (pageData.value.is_activity == 1) {
				sum = payPrice.value * pageData.value.activity_discount;
				console.log(payPrice.value, pageData.value.activity_discount);
				discount_money.value = computationalAccuracy(payPrice.value - sum);
			} else {
				sum = payPrice.value;
			}
		} else {
			sum = payPrice.value;
		}
	} else {
		sum = payPrice.value;
	}
	return computationalAccuracy(sum);
};
//获取工作人员列表
let usersref = ref(null);
let userList = ref('');
const handleInfoError = () => {
	$require.HttpPost('/h5/user/userList', {
		pageNum: 1,
		pageSize: 100,
		property_status: 10
	})
		.then(res => {
			if (res.data) {
				userList.value = res.data.list;
				usersref.value.fade();
			}
		});
};
let confirmbefore = () => {
	console.log(payPrice.value);
	if (!payPrice.value) {
		return Toast('请输入金额');
	}
	calcSum();
	maskShow.value = true;
};
// 确认收费
let confirmCharge = () => {
	if (disabled.value) return console.log('请勿重复提交');
     disabled.value = true;
	let chargeForm = {
		contract_fee_name: route.query.title,
		discount: pageData.value.activity_discount,
		coupon_price: 0,
		balance_deduction: 0,
		discount_money: discount_money.value,
		key_pay_price: calcSum()
	};
	$require.HttpPost('/h5/payment/weChatNotice', chargeForm)
		.then(res => {
			if (res.data || res.code == 0) {
				disabled.value = false;
				// $require.HttpPost('/h5/payment/weChatReturn', {
				// 	orderNo: res.data,
				// 	status: 1
				// }).then(() => {
				// 		maskShow.value = false
				// 		getDetail()
				// 	})
				// .replace(/-/g,"/")
				// res.data = res.data.replace(/\\u0026/g,'&')
				if (calcSum() == 0) {
				$require.HttpPost('/h5/payment/weChatReturn', {
					orderNo: res.data,
					status: 1
				}).then(() => {
						maskShow.value = false;
						getDetail();
					});
			} else {
					window.location.href = res.data.url;
					maskShow.value = false;
					getDetail();
			}
			}
		});
};
function hasHttp(item) {
  if (item.includes('http')) {
    return item;
  }
  return process.env.VUE_APP_IMG_URL + item;
}
</script>
<template>
	<div class="othFee" v-if="pageData">
		<div v-if="pageData.owe_type != 0">
			<!-- <img
				v-if="pageData.is_activity == 1 && pageData.owe_type == 1"
				:src="pageData.activity_image"
				style="margin-bottom: 10px;width: 100%"
			/> -->
 <van-swipe class="my-swipe"   v-if="pageData.is_activity == 1 && pageData.owe_type == 1" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in pageData.activity_image" :key="index">
        <img class="activeImage" :src="hasHttp(item)" alt="">
      </van-swipe-item>
    </van-swipe>
			<div class="pubBox">
				<div class="list">
					<div class="listTitle">缴费单位</div>
					<div>{{ pageData.market_name }}</div>
				</div>
				<div class="list">
					<div class="listTitle">户主信息</div>
					<div>{{ pageData.contact_name }}</div>
				</div>
				<div class="list">
					<div class="listTitle">费用金额</div>
					<div>￥{{ pageData.key_total_price_all }}</div>
				</div>
				<div class="list">
					<div class="listTitle">已缴金额</div>
					<div>￥{{ pageData.key_update_price_sum }}</div>
				</div>
				<div class="line"></div>
				<div class="list">
					<div class="font28 fontBold">应缴总金额</div>
					<div class="font40 fontBold">￥{{ pageData.key_pay_price }}</div>
				</div>
				<div class="list" v-if="pageData.is_activity == 1 && pageData.owe_type == 1">
					<div class="listTitle">活动折扣率</div>
					<!-- <div style="color: #FD6336">{{ pageData.activity_discount }}折</div> -->
					<div style="color: #FD6336">{{ pageData.activity_discount * 10 }}折</div>
				</div>
			</div>
			<div class="pubBox">
				<div class="fontBold font32 list">缴费金额</div>
				<!-- <div
        class="list fontBold font48"
        v-if="pageData.fee_setting.update_type==20"
      >￥{{ pageData.key_pay_price_discount }}
				</div>-->
				<div class="noSearchData" v-if="pageData.owe_type == 0">
					<img src="src/assets/image/search.png" />
					<span>暂未查询到欠费</span>
				</div>
				<div class="inputMoney" v-else>
					<span>￥</span>
					<input  v-num v-model="payPrice" @blur="inputChange" placeholder="点击输入缴费金额" />
				</div>
			</div>

			<div class="tip">最高缴费金额不能超过个人应缴费用总金额。</div>
			<div class="btn" @click="confirmbefore">确认缴纳</div>
		</div>
		<div class="pubBox" v-else>
			<div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
				<img src="../../assets/image/fee.png" />
				<div
					class="fontBold font48"
					style="width: 290px;text-align: center;margin-top: 16px;"
				>恭喜，您的“{{ ctx.router.currentRoute.value.query.title }}”已缴纳完成</div>
			</div>
			<div class="line"></div>
			<div class="list">
				<div class="listTitle">缴费单位</div>
				<div>{{ pageData.market_name }}</div>
			</div>
			<div class="list">
				<div class="listTitle">户主信息</div>
				<div>{{ pageData.contact_name }}</div>
			</div>
			<div class="list">
				<div class="listTitle">费用金额</div>
				<div>￥{{ pageData.key_total_price_all }}</div>
			</div>
			<div class="list">
				<div class="listTitle">已缴金额</div>
				<div>￥{{ pageData.key_update_price_sum }}</div>
			</div>
		</div>
		<div class="help">
			<div @click="handleInfoError">疑问?</div>
		</div>
		<div class="mask" v-if="maskShow">
			<div class="maskMain">
				<div class="mainTitle">
					支付详情
					<img src="../../assets/image/dele.png" @click="maskShow = false" />
				</div>
				<div class="mainContent">
					<div class="contentList">
						<span class="fontBold" style="color: black">支付金额</span>
						<span class="fontBold" style="color: black;font-size: 24px">￥{{ calcSum() }}</span>
					</div>
					<div class="contentList">
						<span>缴费金额</span>
						<span style="color: black">￥{{ payPrice }}</span>
					</div>
					<div class="contentList" v-if="pageData.is_activity == 1 && discount_money > 0">
						<span>折扣减免</span>
						<span style="color: #FD6336">￥{{ discount_money }}</span>
					</div>
				</div>
				<div class="btns">
					<div @click="maskShow = false">取消</div>
					<div @click="confirmCharge" :class="disabled?'disabled':''">确认支付</div>
				</div>
			</div>
		</div>
		<!-- 工作人员列表 -->
		<rConfirm slots ref="usersref" :btn="false" titles="联系市场人员">
			<div class="linkform">
				<van-cell-group inset>
					<div v-for="item in userList" :key="item.user_id">
						<van-field v-model="item.name" readonly label="市场管理员" />
						<van-field v-model="item.mobile" readonly label="联系电话">
							<template #button>
								<div style="color: #81B51D;" @click="$common.callPhone(item.mobile)">
									<van-icon name="phone" />
								</div>
							</template>
						</van-field>
					</div>
				</van-cell-group>
			</div>
		</rConfirm>
	</div>
</template>
<style scoped lang="scss">
.othFee {
	width: 100%;
	overflow-x: hidden;
	padding: 25px;
	box-sizing: border-box;
	font-size: 28px;
	font-weight: 400;
	background-color: #f9f9f9;
	min-height: 100vh;
	  .activeImage{
    width: 100%;
    height: 300px;
    object-fit: contain;
  }

	.mask {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 40px;
		box-sizing: border-box;

		.maskMain {
			flex: 1;
			background: #ffffff;
			border-radius: 20px 20px 20px 20px;

			.mainTitle {
				position: relative;
				height: 120px;
				text-align: center;
				line-height: 120px;
				font-size: 36px;
				font-weight: bold;
				color: #000000;
				border-bottom: 2px solid #efefef;

				img {
					position: absolute;
					right: 40px;
					top: 30%;
					transform: translateY(-30%);
				}
			}

			.mainContent {
				padding: 40px;
				box-sizing: border-box;

				.contentList {
					display: flex;
					align-items: center;
					justify-content: space-between;
					line-height: 70px;

					span {
						font-size: 32px;
						color: rgba(0, 0, 0, 0.6);
					}
				}
			}

			.btns {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 40px;
				box-sizing: border-box;
				font-size: 32px;

				div {
					width: 48%;
					height: 96px;
					background: #ffffff;
					line-height: 96px;
					text-align: center;
					border-radius: 200px 200px 200px 200px;
					border: 2px solid rgba(0, 0, 0, 0.1);

					&:last-child {
						background: #85c226;
						border: none;
						color: #ffffff;
					}
				}
				.disabled{
						background: #a0a19d !important;
				}
			}
		}
	}

	.help {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		div {
			text-align: center;
			width: 100%;
			height: 28px;
			font-size: 24px;
			font-weight: 500;
			color: #000000;
		}
	}

	.btn {
		height: 96px;
		background: #85c226;
		border-radius: 200px 200px 200px 200px;
		text-align: center;
		line-height: 96px;
		font-size: 36px;
		color: #ffffff;
		margin: 20px 0;
	}

	.tip {
		flex: 1;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.6);
	}

	.inputMoney {
		overflow: hidden;
		display: flex;
		align-items: center;
		input {
			flex-shrink: 0;
			height: 100px;
			font-size: 48px;
			font-family: PingFang SC-Bold, PingFang SC;
			font-weight: bold;
			color: #000000;
		}
		input::placeholder {
			color: #cccccc;
			font-size: 48px;
			font-family: PingFang SC-Bold, PingFang SC;
			font-weight: bold;
		}
	}
}
</style>
